import React, { useEffect, useState } from "react";
import {
  Block,
  Button,
  TooltipComponent,
} from "../../../../../components/Component";
import {
  CURRENCY_CODE,
  SERVICE_FEES,
  TERMS_AND_CONDITIONS,
  covertToNumberOnly,
  numberFormatterWithDecimal,
  openLinkInNewTab,
  showErrorToast,
} from "../../../../../utils";
import { TenantPaymentWizardContext } from "./TenantRentPayment";
import { getPaymentFeeAmount, payRentAmount } from "../../TanantData";
import { Spinner } from "reactstrap";
export const TenantPaymentReview = (props) => {
  const { data, updateData } = React.useContext(TenantPaymentWizardContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let amount = parseFloat(data?.paymentAmount ?? "0");
    getServiceFeeAmount(amount);
  }, []);

  function onConfirmation() {
    let params = {
      unitTenantId: data.unitTenantId,
      payAmount: parseFloat(covertToNumberOnly(data?.paymentAmount ?? 0)),
    };
    setLoading(true);
    payRentAmount(params, (data, error) => {
      if (error === null && data.data) {
        updateData("paymentInvocieId", data.data.id);
        updateData("paymentInvocieDate", data.data.time);
        props.next();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  }

  function getServiceFeeAmount(amount) {
    getPaymentFeeAmount({ amount: amount }, (data, error) => {
      if (error === null) {
        let total = amount + (data?.serviceFees ?? 0);
        updateData("totalAmountToPay", total);
        updateData("serviceFee", data?.serviceFees ?? 0);
      } else {
        showErrorToast(error.message);
        props.prev();
      }
    });
  }

  return (
    <Block>
      <div className="invoice-bills">
        <div className="table-responsive ">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="">Details</th>
                <th className=""></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="">Payment account</td>
                <td className="">
                  {data?.paymentRemain?.paymentVia?.name} {"**** **** **** "}{" "}
                  {data?.paymentRemain?.paymentVia?.last4}
                </td>
              </tr>
              <tr>
                <td className="">Payment amount</td>
                <td className="">
                  {numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    "" + (data?.paymentAmount ?? 0)
                  )}
                </td>
              </tr>
              <tr>
                <td className="d-flex align-items-center">
                  <label className="mb-0 me-1" htmlFor="servicefee">
                    Service fee
                  </label>
                  <TooltipComponent
                    icon="info-fill"
                    iconClass="card-hint text-gray"
                    direction="right"
                    id="servicefee"
                    text={SERVICE_FEES}
                  />
                </td>
                <td className="">
                  {numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    "" + (data?.serviceFee ?? 0)
                  )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className="fw-bold h-40">
                <td>Total</td>
                <td className="">
                  {numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    "" + data.totalAmountToPay
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div>
          {/* <div className="border p-2 rounded-3 my-2">
            Service fee is non-refundable.
          </div> */}
          <div className="alert alert-icon alert-gray my-2" role="alert">
            {" "}
            <em className="icon ni ni-alert-circle"></em> Service fee is
            non-refundable.
          </div>
          <p>
            Please read this entire agreement before proceeding. By clicking on
            the "Submit payment" button bellow, you accept the Terms and
            conditions. If you do not accept these terms, do not click "Submit
            payment" bellow.
          </p>
          <div className="custom-control custom-control-sm custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"stay-signed-in"}
              name={"stay-signed-in"}
              // defaultChecked={isAcceptTermAndCondition}
              onChange={(e) =>
                updateData("isAcceptTermAndCondition", e.target.checked)
              }
            />
            <label className="custom-control-label" htmlFor={"stay-signed-in"}>
              <p className="fSize14">
                I have read and accept the{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href={undefined}
                  className="text-primary "
                  onClick={(e) => openLinkInNewTab(TERMS_AND_CONDITIONS, e)}
                >
                  Terms and conditions
                </a>
              </p>
            </label>
          </div>
        </div>
        <div className="nk-notes ff-italic fs-12px text-soft mt-3">
          <div className="form-group">
            <Button
              type="button"
              color="gray"
              size="md"
              className="btn-dim"
              onClick={() => {
                updateData("isAcceptTermAndCondition", false);
                props.prev();
              }}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary"
              size="md"
              disabled={!data?.isAcceptTermAndCondition}
              className="mx-4"
              onClick={() => onConfirmation()}
            >
              {loading ? <Spinner size="sm" color="light" /> : "Submit payment"}
            </Button>
          </div>
        </div>
      </div>
    </Block>
  );
};

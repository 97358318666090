import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Form } from "reactstrap";
import {
  AltitudeModal,
  DatePicker,
  ErrorText,
  FormInput,
  FormInputType,
  PropertyDropdown,
} from "../../../components/Component";
import { UnitDropdown } from "../../../components/dropdown/UnitDropdown";
import {
  AppText,
  covertToNumberOnly,
  isValidEmail,
  numberFormatter,
  showErrorToast,
  showSuccessToast,
} from "../../../utils";
import { inviteTenant } from "../tenant/TanantData";
import moment from "moment";

const AddTenants = ({ isOpen, property, unit, onSave, onCancel }) => {
  const [formData, setFormData] = useState();
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    if (isOpen && unit) {
      setFormData((prev) => ({
        ...prev,
        unit: { id: unit.id, label: unit.unitName, value: unit.unitName },
        property: property
          ? { id: property?.id, label: property.name, value: property.name }
          : null,
        rent: unit?.marketRent ?? "",
      }));
    }
  }, [isOpen, unit]);

  const resetForm = () => {
    setFormData({});
    setLoading(false);
    setIsDataError(false);
    setError(null);
  };

  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  const onFormSubmit = () => {
    if (
      !isValidEmail(formData?.email ?? "") ||
      formData?.rent?.length === 0 ||
      formData?.rent === undefined ||
      formData?.startDate?.length === 0 ||
      formData?.startDate === undefined ||
      formData?.unit?.length === 0 ||
      formData?.unit === undefined
    ) {
      setIsDataError(true);
    } else {
      let params = {
        //tenantId: formData?.email?.id ?? null,
        unitId: formData?.unit?.id,
        rentAmount: parseFloat(covertToNumberOnly(formData?.rent ?? 0)),
        startDate: formData?.startDate
          ? moment(formData?.startDate).format("YYYY-MM-DD")
          : null,
        email: formData?.email,
      };

      if (formData?.endDate) {
        params.endDate = moment(formData?.endDate).format("YYYY-MM-DD");
      }

      setLoading(true);
      inviteTenant(params, (data, error) => {
        if (error === null) {
          showSuccessToast(AppText.invitationSendSuccessfully);
          onSave();
          onFormCancel();
        } else {
          showErrorToast(error.message);
        }
        setLoading(false);
      });
    }
  };

  return (
    <AltitudeModal
      size={"md"}
      isShowFooter={true}
      isOpen={isOpen}
      loading={loading}
      toggle={() => onFormCancel()}
      title={"Invite resident"}
      onSave={() => onFormSubmit()}
      saveButtonTitle="Invite"
    >
      <Form
        className="row my-1"
        onSubmit={handleSubmit(onFormSubmit)}
        autoComplete="off"
      >
        <Col md="12" className="mt-2 mt-md-0 ">
          <div className="form-group">
            <label className="form-label">Resident email</label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="email"
                name="email"
                className="form-control-lg form-control"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            {isDataError && !isValidEmail(formData?.email ?? "") && (
              <p className="invalid">{AppText.invalidEmail}</p>
            )}
          </div>
        </Col>
        <Col md="12" className="mt-3">
          <label className="form-label fs-16px fw-700">Lease</label>
        </Col>
        <Col md="4" className="mt-1">
          <div className="form-group">
            <div className="form-control-wrap">
              <FormInput
                labelGroupClass="mt-n1"
                label={"Base rent"}
                type={FormInputType.amount}
                value={formData?.rent}
                onTextChange={(text) =>
                  setFormData({
                    ...formData,
                    rent: text,
                  })
                }
              />
              {isDataError &&
                (formData?.rent === undefined ||
                  formData?.rent?.length === 0) && (
                  <p className="invalid mt-n20">This field is required</p>
                )}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label">Start date</label>
            <DatePicker
              value={formData?.startDate ?? null}
              onChange={(date) => {
                setFormData({ ...formData, startDate: date });
              }}
            />
            {isDataError &&
              (formData?.startDate === undefined ||
                formData?.startDate?.length === 0) && (
                <p className="invalid">This field is required</p>
              )}
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label">End date</label>
            <DatePicker
              value={formData?.endDate ?? null}
              onChange={(date) => {
                setFormData({ ...formData, endDate: date });
              }}
            />
            {/* {isDataError &&
              (formData.endDate === undefined ||
                formData?.endDate?.length === 0) && (
                <p className="invalid">This field is required</p>
              )} */}
          </div>
        </Col>
        <Col md="6" className="mt-3 ">
          <div className="form-group">
            <label className="form-label">Property</label>
            <div className="form-control-wrap">
              <PropertyDropdown
                value={formData?.property}
                onSelect={(e) => {
                  if (e.id !== formData?.property?.id || !formData?.property) {
                    setFormData({ ...formData, property: e, unit: null });
                  }
                }}
              />
            </div>
            {isDataError && formData?.unit === undefined && (
              <p className="invalid">This field is required</p>
            )}
          </div>
        </Col>
        <Col md="6" className="mt-3 ">
          <div className="form-group">
            <label className="form-label">Unit</label>
            <div className="form-control-wrap">
              <UnitDropdown
                value={formData?.unit}
                propertyId={formData?.property?.id}
                onSelect={(e) => setFormData({ ...formData, unit: e })}
              />
            </div>
            {isDataError && formData?.unit === undefined && (
              <p className="invalid">This field is required</p>
            )}
          </div>
        </Col>
      </Form>
    </AltitudeModal>
  );
};

export default AddTenants;

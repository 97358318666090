import React, { useState, useEffect } from "react";
import { Form, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  AltitudeModal,
  DatePicker,
  DateRangePicker,
  ErrorText,
  FormInput,
  FormInputType,
  IconButton,
  InvoiceSearchDropdown,
  RadioButtonOptions,
  TenantSearchDropdown,
} from "../../../components/Component";
import { RadioOptionTypes } from "../../../utils";
import { addCustomInvoice } from "./Payment/InvoiceData";
import moment from "moment";
import {
  covertToNumberOnly,
  getNumberAsText,
  numberFormatter,
  showErrorToast,
  showSuccessToast,
} from "../../../utils";
import { Storage } from "../../../services/storage/storage";
import { logDOM } from "@testing-library/react";

const AddInvoice = ({ isOpen, onSave, onCancel, selectedInvoice, tenant }) => {
  const [formData, setFormData] = useState({ effectiveDate: new Date() });
  const [additionalFields, setAdditionalFields] = useState([
    { description: null, amount: null },
  ]);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isError, setIsError] = useState(false);

  const user = Storage.getLoggedInUser().user;

  useEffect(() => {
    if (isOpen) {
      let tenantObject = null;
      if (tenant) {
        tenantObject = {
          id: tenant.id,
          label: tenant.name,
          value: tenant.name,
        };
      }

      if (selectedInvoice) {
        setFormData({
          ...formData,
          id: selectedInvoice?.id,
          invoiceType: selectedInvoice?.invoiceType === "RECURRING" ? 1 : 0,
          selectedTenant: tenantObject,
          effectiveDate: selectedInvoice.effectiveDate
            ? moment(selectedInvoice.effectiveDate).toDate()
            : new Date(),
          effectiveEndDate: selectedInvoice.endDate
            ? moment(selectedInvoice.endDate).toDate()
            : null,
        });
        setAdditionalFields(
          selectedInvoice?.items?.map((item) => ({
            description: {
              id: item?.itemId,
              label: item?.title,
              value: item?.title,
            },
            amount: item?.amount ? numberFormatter("", "" + item?.amount) : "",
          })) || []
        );
      } else if (tenant) {
        setFormData({
          ...formData,
          selectedTenant: tenantObject,
          effectiveDate: new Date(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedInvoice]);

  useEffect(() => {
    if (
      !formData?.selectedTenant ||
      !formData?.effectiveDate ||
      (formData.invoiceType && !formData?.effectiveEndDate) ||
      additionalFields.some(
        (field) =>
          (field.description ?? "").length === 0 ||
          (field.amount ?? "").length === 0
      )
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [formData, additionalFields]);

  const resetForm = () => {
    setFormData({ effectiveDate: new Date() });
    setIsError(false);
    setAdditionalFields([{ description: null, amount: null }]);
    setLoading(false);
    setIsDisable(true);
  };

  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  const handleAddField = () => {
    setAdditionalFields([
      ...additionalFields,
      { description: null, amount: null },
    ]);
  };

  const handleRemoveField = (index) => {
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      additionalFields: additionalFields,
    }));
  }, [additionalFields]);

  const onFormSubmit = () => {
    if (
      !formData.selectedTenant ||
      !formData.effectiveDate ||
      (formData.invoiceType && !formData.effectiveEndDate) ||
      !additionalFields.every((field) => field.description && field.amount)
    ) {
      setIsError(true);
    } else {
      setIsError(false);

      let params = {
        id: formData?.id,
        tenantId: formData.selectedTenant.id,
        effectiveDate: moment(formData.effectiveDate).format("YYYY-MM-DD"),
        invoiceType: formData.invoiceType ? "RECURRING" : "SINGLE",
        endDate: formData.effectiveEndDate
          ? moment(formData.effectiveEndDate).format("YYYY-MM-DD")
          : null,
        items: formData.additionalFields.map((data) => {
          return {
            title: data?.description.label,
            amount: data?.amount
              ? parseFloat(covertToNumberOnly("" + data?.amount))
              : 0,
          };
        }),
      };
      setLoading(true);
      addCustomInvoice(params, (data, error) => {
        if (error === null) {
          setFormData(data);
          onFormCancel();
          onSave();
          showSuccessToast("Your invoice has been saved successfully");
        } else {
          showErrorToast(error.message);
        }
        setLoading(false);
      });
    }
  };

  const { handleSubmit } = useForm();
  return (
    <AltitudeModal
      size="lg"
      isShowFooter={true}
      isOpen={isOpen}
      loading={loading}
      toggle={() => onFormCancel()}
      title="Add invoice"
      onSave={handleSubmit(onFormSubmit)}
      saveButtonTitle="Save"
      // disabled={isDisable}
    >
      <Form className="row gy-4" autoComplete="off">
        <Col md="12" className="mt-3">
          <div className="form-group">
            <div className="form-control-wrap">
              <RadioButtonOptions
                name="status"
                label={"Is it a one-time or recurring invoice?"}
                size={6}
                optionType={RadioOptionTypes.status}
                onChange={(index) =>
                  setFormData((prev) => ({ ...prev, invoiceType: index === 1 }))
                }
                activeIndex={formData.invoiceType ? 1 : 0}
              />
            </div>
          </div>
        </Col>
        <Col md="12" className="mt-1">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        </Col>
        <Col md="6" className="">
          <div className="form-group">
            <label className="form-label">Resident</label>
            <div className="form-control-wrap pac-container">
              <TenantSearchDropdown
                value={formData?.selectedTenant}
                className={"w-min-200px"}
                isMyTenantOnly={true}
                onSelect={(item) =>
                  setFormData((prev) => ({ ...prev, selectedTenant: item }))
                }
                placeholder="Search resident"
              />
              {isError && !formData?.selectedTenant && (
                <ErrorText />
              )}
            </div>
          </div>
        </Col>
        <Col md="6" className="">
          <div className="form-group">
            <label className="form-label">Effective on</label>
            {formData.invoiceType ? (
              <div className="form-control-wrap">
                <DateRangePicker
                  start={formData?.effectiveDate ?? null}
                  end={formData?.effectiveEndDate ?? null}
                  onChangeStart={(date) => {
                    setFormData((prev) => ({ ...prev, effectiveDate: date }));
                  }}
                  onChangeEnd={(date) => {
                    setFormData((prev) => ({
                      ...prev,
                      effectiveEndDate: date,
                    }));
                  }}
                />
                <span className="fs-11px text-gray">{`Will be charged every ${getNumberAsText(
                  user?.companyInfo?.invoiceDay ?? 0
                )} day of each month  `}</span>
                <a
                  href={`${process.env.PUBLIC_URL}/user-invoice-setting`}
                  className="clickable"
                >
                  <span className="fs-11px">{`Change`}</span>
                </a>
                {isError && !formData?.effectiveEndDate && (
                   <ErrorText />
                )}
             </div>
            ) : (
              <DatePicker
                value={formData?.effectiveDate ?? null}
                minDate={new Date()}
                onChange={(date) =>
                  setFormData((prev) => ({ ...prev, effectiveDate: date }))
                }
              />
            )}
          </div>
        </Col>

        <Col md="6" className="">
          <label className="form-label">Description</label>
        </Col>
        <Col md="6" className="">
          <label className="form-label">Amount</label>
        </Col>
        {additionalFields.map((field, index) => (
          <React.Fragment key={index}>
            <Col md="6" className="mt-1">
              <div className="form-group">
                <div className="form-control-wrap">
                  <InvoiceSearchDropdown
                    value={field.description}
                    onSelect={(item) =>
                      setAdditionalFields((prev) => {
                        const updatedFields = [...prev];
                        updatedFields[index].description = item;
                        return updatedFields;
                      })
                    }
                    onSearch={(text) => {
                      setAdditionalFields((prev) => {
                        const updatedFields = [...prev];
                        updatedFields[index].description = {
                          id: 0,
                          label: text,
                          value: text,
                        };
                        return updatedFields;
                      });
                    }}
                    placeholder="Search"
                  />
                   {isError && !field?.description && (
                   <ErrorText />
                  )}
                </div>
              </div>
            </Col>
            <Col md="5" className="mt-1">
              <div className="form-group">
                <div className="form-control-wrap">
                  <FormInput
                    labelGroupClass="mt-n1"
                    type={FormInputType.amount}
                    value={field.amount}
                    onTextChange={(text) =>
                      setAdditionalFields((prev) => {
                        const updatedFields = [...prev];
                        updatedFields[index].amount = text;
                        return updatedFields;
                      })
                    }
                  />
                   {isError && !field.amount && (
                      <ErrorText className={"mt-n2"}/>
                   )}
                </div>
              </div>
            </Col>
            <Col md="1" className="text-end pt-1 mt-1">
              {index === 0 ? (
                <IconButton
                  color="success"
                  icon="plus"
                  size="sm"
                  onClick={handleAddField}
                  disabled={
                    !additionalFields.every(
                      (field) => field.description && field.amount
                    )
                  }
                />
              ) : (
                <IconButton
                  icon="trash"
                  size="sm"
                  className="btn btn-danger"
                  onClick={() => handleRemoveField(index)}
                />
              )}
            </Col>
          </React.Fragment>
        ))}
      </Form>
    </AltitudeModal>
  );
};

export default AddInvoice;

import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-path";
import { getPayoutAccountData } from "../../utils";

export function amenitiesDropdown(type, callback) {
  apiService
    .postCall(APIServicePath.unitAmenities, { type: type })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function unitList(propertyId, callback) {
  apiService
    .postCall(APIServicePath.unitList, { propertyId: propertyId })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.unitName,
          value: element.unitName,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function tenantList(search, isMyTenantOnly = false, callback) {
  let params = {
    pageInfo: {
      pageIndex: 1,
      itemsPerPage: 10,
    },
    sorting: {
      field: "name",
      order: "asc",
    },
    search: search,
    isShortInfo: true,
    isMyTenantOnly: isMyTenantOnly,
  };

  apiService
    .postCall(APIServicePath.tenantList, params)
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        let name = element.firstName?.trim() + " " + element.lastName?.trim();
        list.push({
          id: element.id,
          label: name,
          value: name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function propertyList(callback) {
  apiService
    .postCall(APIServicePath.propertyList)
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchChargeItemHistoryList(tenantId, callback) {
  let params = {
    tenantId: tenantId,
  };
  apiService
    .postCall(APIServicePath.chargeItemHistoryList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function unitHistoryList(tenantId, callback) {
  let params = tenantId
    ? {
        tenantId: tenantId,
      }
    : {};
  apiService
    .postCall(APIServicePath.unitHistoryList, params)
    .then((data) => {
      let list = LocationDropdownConvertor(data.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchBankList(callback) {
  apiService
    .getCall(APIServicePath.payoutBankList)
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        let bank = getPayoutAccountData(element);
        bank && list.push(bank);
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchTenantBankList(callback) {
  apiService
    .postCall(APIServicePath.tenantBank)
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.banks?.length ?? 0; index++) {
        const bank = data.banks[index];
        let name = bank.bankName + " (****" + bank.last4 + ")";
        list.push({
          id: bank.id,
          label: name,
          value: name,
        });
      }
      for (let index = 0; index < data.cards?.length ?? 0; index++) {
        const card = data.cards[index];
        let name = card.brand + " (****" + card.last4 + ")";
        list.push({
          id: card.id,
          label: name,
          value: name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function searchInvoiceList(search, callback) {
  let params = {
    search: search,
  };

  apiService
    .postCall(APIServicePath.searchInvoice, params)
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.title,
          value: element.title,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}


///////// Converter //////////////////////////

const LocationDropdownConvertor = (item) => {
  let list = [];
  item.map((item) => {
    let text = `${item?.property?.name}, Apt. ${item?.name}`;
    let object = {
      id: item?.id,
      value: text,
      label: text,
    };
    list.push(object);
  });
  return list;
};

import React from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockTitle,
  BlueText,
  ConfirmationAlert,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  ReactDataTable,
  TableTitle,
  TooltipComponent,
} from "../../../components/Component";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import AddUnit from "./AddUnit";
import { useState } from "react";
import { useEffect } from "react";
import AddTenants from "./AddRemoveTenants";
import { fetchUnits, unitRemove } from "./PropertyData";
import {
  AppText,
  CURRENCY_CODE,
  DATE_FORMAT,
  listAsNewLineString,
  numberFormatter,
  showErrorToast,
  toCapitalize,
} from "../../../utils";
import moment from "moment";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import MoreOption from "../../../components/more/MoreOption";

const PropertyUnit = ({ property }) => {
  const [selectedUnit, setSelectedUnit] = useState();
  const [units, setUnits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [tenantsModal, setTenantsModal] = useState(false);

  useEffect(() => {
    if (currentPage !== 0) {
      loadUnits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // API--------------------
  function loadUnits() {
    let params = {
      pageInfo: { pageIndex: currentPage, itemsPerPage: itemPerPage },
      propertyId: property.id,
    };
    fetchUnits(params, (data, error) => {
      if (error === null) {
        setUnits(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
    });
  }

  function removeUnit(item) {
    ConfirmationAlert(
      "Removing unit!",
      "Do you want to remove unit " + item.unitName + "?",
      () => {
        unitRemove({ id: item.id }, (data, error) => {
          if (error === null) {
            loadUnits();
          } else {
            showErrorToast(
              error.message.length > 0 ? error.message : AppText.connectError
            );
          }
        });
      }
    );
  }

  const InvitedText = ({ text, date, id }) => {
    return (
      <div className="d-flex align-items-center">
        <span className="me-1">{text + " (invited) "}</span>

        {date && (
          <TooltipComponent
            icon="info-fill"
            iconClass="card-hint text-gray"
            direction="right"
            containerClassName=""
            id={"invited-user-" + id}
            text={"Invited at " + moment(date).format("MMM DD, YYYY hh:mm a")}
          />
        )}
        <br />
      </div>
    );
  };
  const onViewClick = (data, item) => {
    const unitName = data?.firstName + data?.lastName;
    history.push({
      pathname: `${process.env.PUBLIC_URL}/tenant-detail/${data.tenantId}-${unitName}`,
      state: { path: location.pathname + location.hash },
    });
  };

  const onEdit = (item) => {
    setSelectedUnit(item);
    setModal(true);
  };

  const onInvite = (item) => {
    setSelectedUnit(item);
    setTenantsModal(true);
  };

  const onReceivePayment = (item) => {};

  return (
    <div className="card-aside-wrap">
      <div className="card-content">
        <div className="card-inner p-0 max-w-856">
          <Block>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockTitle tag="h5">Units</BlockTitle>
                <a
                  href="#add"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal(true);
                  }}
                  className="link link-sm"
                >
                  <p className="fSize14 fw-normal">Add unit</p>
                </a>
              </BlockBetween>
            </BlockHead>
            <ReactDataTable
              data={units}
              columns={UnitTableColumns(
                onEdit,
                removeUnit,
                onInvite,
                onReceivePayment,
                onViewClick
              )}
              totalItemCount={totalItems}
              className=" w-100 overflow-visiable card-bordered remove-shadow border rounded-3"
              pagination
              onRowSelected={(row) => {}}
              onRowClicked={(row) => {}}
              noDataNode={
                <div className="text-center p-3">
                  <span className="text-silent">
                    You have not added any unit yet
                  </span>
                </div>
              }
            />
            {totalItems === 0 && <div className="text-center p-3" />}
          </Block>
          {modal && (
            <AddUnit
              isOpen={modal}
              propertyDetails={property}
              item={selectedUnit}
              onCancel={() => {
                setModal(false);
                setSelectedUnit();
              }}
              onSave={() => {
                loadUnits();
              }}
            />
          )}
          {tenantsModal && (
            <AddTenants
              isOpen={tenantsModal}
              property={property}
              unit={selectedUnit}
              onCancel={() => {
                setTenantsModal(false);
                setSelectedUnit();
              }}
              onSave={() => {
                loadUnits();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyUnit;

export const UnitTableColumns = (
  onEdit,
  onRemove,
  onInvite,
  onReceivePayment,
  onViewClick
) => {
  return [
    {
      name: <TableTitle title={"Unit"} />,
      sortField: "unitName",
      width: "100px",
      selector: (row) => (
        <div className="project-info">
          <h6 className="title fSize14">{row.unitName}</h6>
        </div>
      ),
      sortable: true,
      sortFunction: unitInsensitiveSort,
    },
    {
      name: <TableTitle title={"Resident"} />,
      cell: (item) => (
        <div className="text-secondary my-2">
          <span>
            {item.invitedData &&
              item.invitedData?.length > 0 &&
              item.invitedData.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    {data.status === "INVITED" && (
                      <InvitedText
                        text={data.email?.trim()}
                        date={data.createdDate}
                        id={index}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {item.tenantUsers &&
              item.tenantUsers?.length > 0 &&
              item.tenantUsers.map((data, index) => {
                let name =
                  toCapitalize(data.firstName?.trim()) +
                  " " +
                  toCapitalize(data.lastName?.trim());
                return (
                  <React.Fragment key={index}>
                    {data.status === "PENDING" ? (
                      <InvitedText
                        text={name}
                        date={data.createdDate}
                        index={index}
                      />
                    ) : (
                      <>
                        <BlueText
                          title={name}
                          className={"my-2"}
                          onClick={(ev) => {
                            ev.preventDefault();
                            onViewClick(data);
                          }}
                        />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {(item.tenantUsers?.length ?? 0) === 0 &&
              (item.invitedData?.length ?? 0) === 0 &&
              "-"}
          </span>
        </div>
      ),
    },
    {
      name: <TableTitle title={"Balance"} align={"end"} />,
      right: true,
      width: "120px",
      cell: (item) => (
        <div className="w-max-250px text-secondary my-2 text-end">
          <span>
            {item.invitedData &&
              item.invitedData?.length > 0 &&
              item.invitedData.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    {data.status === "INVITED" && (
                      <>
                        {"-"} <br />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {item.tenantUsers &&
              item.tenantUsers?.length > 0 &&
              item.tenantUsers.map((data, index) => {
                let dueAmount =
                  data.totalDueAmount - data.totalProcessingAmount;
                return (
                  <React.Fragment key={index}>
                    {data.status === "PENDING" ? (
                      <>
                        {"-"}
                        <br />
                      </>
                    ) : (
                      <>
                        <label className="my-1">
                          {numberFormatter(CURRENCY_CODE, "" + dueAmount)}
                        </label>
                        <br />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {(item.tenantUsers?.length ?? 0) === 0 &&
              (item.invitedData?.length ?? 0) === 0 &&
              "-"}
          </span>
        </div>
      ),
    },
    {
      name: <TableTitle title={"Lease starts on"} />,
      width: "120px",
      cell: (item) => (
        <div className="w-max-250px text-secondary my-2">
          <span>
            {item.invitedData &&
              item.invitedData?.length > 0 &&
              item.invitedData.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    {data.status === "INVITED" && (
                      <>
                        {"-"} <br />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {item.tenantUsers &&
              item.tenantUsers?.length > 0 &&
              item.tenantUsers.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    {data.status === "PENDING" ? (
                      <>
                        {"-"}
                        <br />
                      </>
                    ) : (
                      <>
                        <label className="my-1">
                          {data?.startDate
                            ? moment(data?.startDate).format(DATE_FORMAT)
                            : "-"}
                        </label>
                        <br />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {(item.tenantUsers?.length ?? 0) === 0 &&
              (item.invitedData?.length ?? 0) === 0 &&
              "-"}
          </span>
        </div>
      ),
    },
    {
      name: <TableTitle title={"Lease ends on"} />,
      width: "120px",
      cell: (item) => (
        <div className="w-max-250px text-secondary my-2">
          <span>
            {item.invitedData &&
              item.invitedData?.length > 0 &&
              item.invitedData.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    {data.status === "INVITED" && (
                      <>
                        {"-"} <br />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {item.tenantUsers &&
              item.tenantUsers?.length > 0 &&
              item.tenantUsers.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    {data.status === "PENDING" ? (
                      <>
                        {"-"}
                        <br />
                      </>
                    ) : (
                      <>
                        <label className="my-1">
                          {data?.endDate
                            ? moment(data?.endDate).format(DATE_FORMAT)
                            : "-"}
                        </label>
                        <br />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </span>
          <span>
            {(item.tenantUsers?.length ?? 0) === 0 &&
              (item.invitedData?.length ?? 0) === 0 &&
              "-"}
          </span>
        </div>
      ),
    },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "60px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <MoreOption
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
            {
              icon: "trash",
              text: "Remove",
              action: () => {
                onRemove(row);
              },
            },
            {
              icon: "user-alt",
              text: "Invite resident",
              action: () => {
                onInvite(row);
              },
            },
            {
              icon: "coin",
              text: "Receive payment",
              action: () => {
                onReceivePayment(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

const unitInsensitiveSort = (rowA, rowB) => {
  const a = rowA?.unitName.toLowerCase();
  const b = rowB?.unitName.toLowerCase();

  if (a > b) return -1;
  if (b > a) return 1;
  return 0;
};

const InvitedText = ({ text, date, id }) => {
  return (
    <div className="d-flex align-items-center my-1">
      <span className="me-1">{text + " (invited) "}</span>

      {date && (
        <TooltipComponent
          icon="info-fill"
          iconClass="card-hint text-gray"
          direction="right"
          containerClassName=""
          id={"invited-user-" + id}
          text={"Invited at " + moment(date).format("MMM DD, YYYY hh:mm a")}
        />
      )}
      <br />
    </div>
  );
};

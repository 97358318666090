import React, { useEffect, useState } from "react";
import Head from "../../../head/Head";
import {
  Card,
  Alert,
  CardBody,
  CardText,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  ErrorText,
  ConfirmationAlert,
  AltitudeModal,
} from "../../../../components/Component";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  getCard,
  getPaymentDetail,
  getPayoutConnectLink,
  getStripeClientSecret,
  getStripeClientSecretForBank,
  getTenantPaymentDetail,
  removeBankTenant,
  removeCardTenant,
  saveCard,
} from "./UserData";
import { UserAccountType } from "../../../../utils/Utils";
import { Storage } from "../../../../services/storage/storage";
import { showErrorToast } from "../../../../utils";
import VerifyMicroDeposite from "./VerifyMicroDeposite";
import LoadingComponent from "../../../spinner/LoadingSpinner";

const UserProfilePaymentPage = ({ sm, updateSm }) => {
  const [isAddCardVisible, setIsAddCardVisible] = useState(false);
  const [card, setCard] = useState();
  const [banks, setBanks] = useState([]);
  const [url, setUrl] = useState();
  const [isVerifyModelVisible, setIsVerifyModelVisible] = useState(false);
  const [fetchTryCount, setFetchTryCount] = useState(0);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

  const stripe = useStripe();

  const [user] = useState(Storage.getLoggedInUser()?.user);
  const [errorVal, setError] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (
      user?.accountType === UserAccountType.vendor ||
      user?.accountType === UserAccountType.manager
    ) {
      fetchBankDetails();
    }
    if (
      user?.accountType === UserAccountType.manager ||
      user?.accountType === UserAccountType.tenant
    ) {
      fetchCard();
    } else if (user?.accountType === UserAccountType.tenant) {
      fetchTenantBankDetails();
      setTimeout(() => {}, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchCard() {
    getCard((card, error) => {
      if (error === null) {
        setCard(card);
      }
    });
  }

  function fetchBankDetails() {
    getPaymentDetail((data, error) => {
      if (error === null) {
        setBanks(data.list?.filter((item) => item?.detailsSubmitted === true));
      }
    });
  }

  function fetchTenantBankDetails(callback) {
    getTenantPaymentDetail((data, error) => {
      if (error === null) {
        setBanks(data.banks);
        callback && callback(data.banks);
      }
    });
  }

  // PM and Vendor
  function fetchPayoutConnectLink(id) {
    setError();
    setIsPaymentProcessing(true);
    getPayoutConnectLink({ id: id }, (data, error) => {
      if (error === null && data?.url) {
        window.open(data.url, "_self");
        // window.open(data.url)
      } else {
        setError(error.message);
      }
      setTimeout(() => {
        setIsPaymentProcessing(false);
      }, 1500);
    });
  }

  // Tenant
  function fetchClientSecret() {
    getStripeClientSecret((data, error) => {
      if (error === null && data?.token) {
        collectBankDetails(data?.token);
      } else {
        setError(error.message);
      }
    });
  }

  function fetchClientSecretForTenantBank(id) {
    getStripeClientSecretForBank(id, (data, error) => {
      if (error === null && data?.token) {
        confirmSetup(data?.token);
      } else {
        setError(error.message);
      }
    });
  }

  function removeTenantBank() {
    let id = banks.length > 0 && banks[0].id;
    if (id) {
      ConfirmationAlert(
        "Removing bank!",
        "Do you want to remove your connected bank?",
        () => {
          removeBankTenant(id, (data, error) => {
            if (error === null) {
              setBanks(banks.filter((bank) => bank.id !== id));
            } else {
              setError(error.message);
            }
          });
        }
      );
    }
  }

  function removeTenantCard() {
    console.log("card: ", card);
    if (card) {
      ConfirmationAlert(
        "Removing card!",
        "Do you want to remove your connected card?",
        () => {
          removeCardTenant(card.id, (data, error) => {
            if (error === null) {
              setCard();
            } else {
              setError(error.message);
            }
          });
        }
      );
    }
  }

  function collectBankDetails(secret) {
    setFetchTryCount(0);
    stripe
      .collectBankAccountForSetup({
        clientSecret: secret,
        params: {
          payment_method_type: "us_bank_account",
          payment_method_data: {
            billing_details: {
              name: user?.firstName?.trim() + " " + user?.lastName?.trim(),
              email: user?.email,
            },
          },
        },
        expand: ["payment_method"],
      })
      .then(({ setupIntent, error }) => {
        if (error) {
          showErrorToast(error.message);
        } else if (setupIntent.status === "requires_confirmation") {
          confirmSetup(secret);
        } else if (setupIntent.status === "succeeded") {
          fetchTenantBankDetails();
        }
      });
  }

  function confirmSetup(secret) {
    stripe
      .confirmUsBankAccountSetup(secret)
      .then(({ setupIntent, error }) => {
        if (error) {
          showErrorToast(error.message);
        } else if (
          setupIntent.status === "requires_action" &&
          setupIntent.next_action
        ) {
          let url =
            setupIntent?.next_action?.verify_with_microdeposits
              ?.hosted_verification_url;
          setUrl(url);
          setIsVerifyModelVisible(true);
          fetchTenantBankDetails();
        } else if (setupIntent.status === "succeeded") {
          fetchBankWith3Try();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }

  function fetchBankWith3Try() {
    setFetchTryCount(fetchTryCount + 1);
    setTimeout(() => {
      fetchTenantBankDetails((list) => {
        fetchTryCount < 3 && list.length === 0 && fetchBankWith3Try();
      });
    }, 1000);
  }

  function onVerify(bank) {
    if (bank.id) {
      fetchClientSecretForTenantBank(bank.id);
    } else {
      showErrorToast(
        "We are facing issue to connect your bank, please try to remove and add again."
      );
    }
  }

  return (
    <React.Fragment>
      <Head title="Settings"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Payment</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="text-end">
            <Button
              className={`toggle btn btn-icon btn-trigger me-2 mt-n1 d-lg-none ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {errorVal && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            {" "}
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}

      {/** Payment Banks for PM and vendor */}
      {(user?.accountType === UserAccountType.vendor ||
        user?.accountType === UserAccountType.manager) && (
        <>
          <BlockHead size="lg">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h6">Payout</BlockTitle>
                <BlockDes>
                  {user?.accountType === UserAccountType.manager ? (
                    <p>Methods to receive payments from tenants.</p>
                  ) : (
                    <p>
                      In order to legally distribute payments through the app,
                      we need to verify your account.
                    </p>
                  )}
                </BlockDes>
              </BlockHeadContent>
              {((user?.accountType === UserAccountType.vendor &&
                banks?.length === 0) ||
                user?.accountType === UserAccountType.manager) && (
                <BlockHeadContent className="text-end">
                  <Button
                    color="primary"
                    className={"ms-4 mt-n5"}
                    onClick={(ev) => fetchPayoutConnectLink()}
                  >
                    <span>{"Add bank account"}</span>
                  </Button>
                </BlockHeadContent>
              )}
            </BlockBetween>
          </BlockHead>
          <Block>
            {banks?.length > 0 ? (
              <>
                {banks.map((bank, index) => {
                  let isBank =
                    (bank?.account?.externalAccounts?.banks?.length ?? 0) > 0;
                  let item = isBank
                    ? bank?.account?.externalAccounts?.banks[0]
                    : bank?.account?.externalAccounts?.cards[0];
                  let name = isBank ? item.bankName : item.brand;
                  let last4 = item.last4;
                  let routingNumber = isBank && item.routingNumber;
                  let expire =
                    !isBank && item.expiryMonth + "/" + item.expiryYear;

                  return (
                    <React.Fragment key={index}>
                      {bank.detailsSubmitted && (
                        <PaymentCardView
                          isBank={isBank}
                          name={name}
                          last4={last4}
                          routingNumber={routingNumber}
                          expire={expire}
                          status={bank.isActive}
                          actions={[PaymentCardViewActionType.change]}
                          onAction={(type) => {
                            fetchPayoutConnectLink(bank.id);
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <CardText className="text-black">
                {user?.accountType === UserAccountType.manager
                  ? "Add your bank(s) account to the profile to receive rent."
                  : "Add your bank account to the profile to receive payments for your work."}
              </CardText>
            )}
          </Block>
        </>
      )}

      {/** Payment Card for PM */}
      {user?.accountType === UserAccountType.manager && (
        <>
          <BlockHead size="lg">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h6">Card to pay with</BlockTitle>
              </BlockHeadContent>
              {!card && (
                <BlockHeadContent className="text-end">
                  <Button
                    color="primary"
                    onClick={(ev) => setIsAddCardVisible(true)}
                  >
                    <span>{"Add card"}</span>
                  </Button>
                </BlockHeadContent>
              )}
            </BlockBetween>
          </BlockHead>
          <Block>
            {card ? (
              <PaymentCardView
                isBank={false}
                name={card?.company}
                last4={card?.last4}
                expire={card?.expMonth + "/" + card?.expYear}
                status={card.status ?? true}
                actions={[PaymentCardViewActionType.change]}
                onAction={() => {
                  setIsAddCardVisible(true);
                }}
              />
            ) : (
              <CardText className="text-black">
                {user?.accountType === UserAccountType.manager
                  ? "Add your card to pay your vendors."
                  : "Add your card to pay your rent."}
              </CardText>
            )}
          </Block>
        </>
      )}

      {/** Payment Card/Bank for Tenant */}
      {user?.accountType === UserAccountType.tenant && (
        <>
          <BlockHead size="lg">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h6">
                  {!card &&  banks.length === 0
                    ? "Connect your U.S. bank account or card to Altitude"
                    : "Your bank/card account"}
                </BlockTitle>
              </BlockHeadContent>
              {!card && banks.length === 0 && (
                <BlockHeadContent className="text-end">
                  {/* <Button color="primary" onClick={(ev) => fetchClientSecret()}>
                    <span>{"Connect bank account"}</span>
                  </Button> */}
                  <Dropdown isOpen={isDropdownOpen} toggle={toggle}>
                    <DropdownToggle className="btn-action" color="primary">
                      <span>Connect</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <ul className="link-list-opt">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#links"
                            onClick={(ev) => {
                              ev.preventDefault();
                              fetchClientSecret();
                            }}
                          >
                            <span>Add bank</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#links"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setIsAddCardVisible(true);
                            }}
                          >
                            <span>Add card</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </Dropdown>
                </BlockHeadContent>
              )}
            </BlockBetween>
          </BlockHead>
          <Block>
            {card ? (
              <PaymentCardView
                        isBank={false}
                        name={card?.company}
                        last4={card?.last4}
                        expire={card?.expMonth + "/" + card?.expYear}
                        status={card.status ?? true}
                        actions={[PaymentCardViewActionType.remove]}
                        onAction={(type) => {
                          removeTenantCard()
                        }}
                      />
            ) : banks.length > 0 ? (
              <>
                {banks.map((bank, index) => {
                  let isActive =
                    bank?.bankStatus === "ACTIVE" ||
                    bank?.bankStatus === "succeeded";
                  return (
                    <React.Fragment key={index}>
                      <PaymentCardView
                        isBank={false}
                        name={bank?.bankName }
                        last4={bank?.last4 }
                        status={isActive}
                        actions={
                          isActive
                            ? [PaymentCardViewActionType.remove]
                            : [
                                PaymentCardViewActionType.verify,
                                PaymentCardViewActionType.remove,
                              ]
                        }
                        onAction={(type) => {
                          if (type === PaymentCardViewActionType.verify) {
                            onVerify(bank);
                          } else {
                            removeTenantBank();
                          }
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>
                <CardText className="text-black">
                  {
                    "This process is safe, secure and allow you to re-use the account you connect for future payments."
                  }
                </CardText>
              </>
            )}
          </Block>
          {isVerifyModelVisible && (
            <VerifyMicroDeposite
              url={url}
              visible={isVerifyModelVisible}
              onClosed={() => {
                fetchTenantBankDetails();
                setUrl();
                setIsVerifyModelVisible(false);
              }}
            />
          )}
        </>
      )}
      {isAddCardVisible &&
        (user?.accountType === UserAccountType.manager ||
          user?.accountType === UserAccountType.tenant) && (
          <AddCardView
            visible={isAddCardVisible}
            stripe={stripe}
            onSave={(data) => {
              setCard(data);
              setIsAddCardVisible(false);
            }}
            onCancel={() => setIsAddCardVisible(false)}
          />
        )}
      <LoadingComponent isLoading={isPaymentProcessing} />
    </React.Fragment>
  );
};
export default UserProfilePaymentPage;

export const AddCardView = ({ visible, stripe, onSave, onCancel }) => {
  const [name, setName] = useState("");
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const [disabled, setDisabled] = useState(true);
  const [nameError, setNameError] = useState();
  const [numberError, setNumberError] = useState();
  const [expiryError, setExpiryError] = useState();
  const [cvcError, setCvcError] = useState();
  const [isNumberComplete, setIsNumberComplete] = useState(false);
  const [isExpireComplete, setIsExpireComplete] = useState(false);
  const [isCVCComplete, setIsCVCComplete] = useState(false);

  const cardStyle = {
    style: {
      base: {
        color: "#3c4d62",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        // ":-webkit-autofill": {
        //   color: "#3c4d62",
        // },
        "::placeholder": {
          color: "#dbdfea",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setLoading(true);
    let element = elements.getElement(CardNumberElement);
    stripe
      .createToken(element, { name: name })
      .then((data) => {
        if (data?.token?.id) {
          saveCardonServer(data.token.id);
        } else if (data.error) {
          setError(data.error.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const handleNumberChange = async (event) => {
    setIsNumberComplete(event.complete);
    setDisabled(
      event.complete === false ||
        !isExpireComplete ||
        !isCVCComplete ||
        name.length === 0
    );
    setNumberError(event.error ? event.error.message : null);
  };

  const handleExpiryChange = async (event) => {
    setIsExpireComplete(event.complete);
    setDisabled(
      event.complete === false ||
        !isNumberComplete ||
        !isCVCComplete ||
        name.length === 0
    );
    setExpiryError(event.error ? event.error.message : null);
  };

  const handleCVCChange = async (event) => {
    setIsCVCComplete(event.complete);
    setDisabled(
      event.complete === false ||
        !isNumberComplete ||
        !isExpireComplete ||
        name.length === 0
    );
    setCvcError(event.error ? event.error.message : null);
  };

  //**************************API's**********************************/
  function saveCardonServer(token) {
    saveCard(token, (card, error) => {
      setLoading(false);
      if (error === null) {
        onSave(card);
      } else {
        setError(error.message);
      }
    });
  }
  //**************************End API's**********************************/
  return (
    <React.Fragment>
      <AltitudeModal
        size={"sm"}
        isShowFooter={true}
        isOpen={visible}
        loading={loading}
        disabled={disabled}
        toggle={() => onCancel()}
        title={"Add card"}
        onSave={() => handleSubmit()}
      >
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              <Icon name="alert-circle" /> {errorVal}
            </Alert>
          </div>
        )}
        <form>
          <div className="form-group">
            <label className="form-label">Name on card</label>
            <div>
              <input
                id="name"
                className="form-control"
                required
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  setDisabled(
                    !isExpireComplete ||
                      !isNumberComplete ||
                      !isCVCComplete ||
                      event.target.value.length === 0
                  );
                }}
                style={cardStyle.style.base}
              />
            </div>
            {nameError && (
              <ErrorText className={"mt-n1"}>{nameError}</ErrorText>
            )}
          </div>
          <div className="form-group">
            <label className="form-label">Card number</label>
            <div>
              <CardNumberElement
                options={cardStyle}
                onChange={handleNumberChange}
                className="form-control"
              />
            </div>
            {numberError && <ErrorText>{numberError}</ErrorText>}
          </div>
          <div className="row">
            <div className="form-group d-block col-6">
              <label className="form-label">Expiration date</label>
              <div>
                <CardExpiryElement
                  options={cardStyle}
                  onChange={handleExpiryChange}
                  className="form-control"
                />
              </div>
              {expiryError && <ErrorText>{expiryError}</ErrorText>}
            </div>
            <div className="form-group d-block col-6">
              <label className="form-label">CVC</label>
              <div>
                <CardCvcElement
                  options={cardStyle}
                  onChange={handleCVCChange}
                  className="form-control"
                />
              </div>
              {cvcError && <ErrorText>{cvcError}</ErrorText>}
            </div>
          </div>
        </form>
      </AltitudeModal>
    </React.Fragment>
  );
};

export const PaymentCardViewActionType = {
  change: "Change",
  remove: "Remove",
  verify: "Verify",
};

export const PaymentCardView = ({
  isBank,
  name,
  last4,
  routingNumber,
  expire,
  status,
  actions,
  onAction,
  loading,
}) => {
  return (
    <Card className="card-bordered shadow-none mb-3">
      <CardBody className="card-inner d-flex justify-between align-items-center">
        <div>
          {(name?.length ?? 0) > 0 && (
            <div className="">
              <CardText className="text m-0">
                <strong>{name}</strong> {"**** **** **** "} {last4}
                {status === true ? (
                  <Icon
                    name="check-circle-fill"
                    style={{}}
                    className="m-1 text-success"
                  ></Icon>
                ) : (
                  <Icon
                    name="cross-circle-fill"
                    style={{}}
                    className="m-1 text-danger"
                  ></Icon>
                )}
              </CardText>
            </div>
          )}
          {expire && !isBank && (
            <CardText className="text">
              <strong>Expires on </strong> {expire}
            </CardText>
          )}
          {routingNumber && isBank && (
            <CardText className="text">
              <strong>Routing number </strong> {routingNumber}
            </CardText>
          )}
        </div>
        {loading ? (
          <Spinner size="sm" color="dark" />
        ) : (
          <div>
            {actions.length > 0 && (
              <div className="d-flex align-items-center">
                {actions?.map((type, index) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href={undefined}
                      key={index}
                      onClick={(ev) => {
                        ev.preventDefault();
                        onAction(type);
                      }}
                      className="link cursor d-block  me-2"
                    >
                      {type}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

import React from "react";
import { Icon } from "../Component";
import { Menu } from "@mui/material";

export const MoreOption = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="text-soft">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        aria-haspopup="true"
        className="text-soft btn btn-icon btn-trigger"
        onClick={handleClick}
      >
        <Icon name="more-h"></Icon>
      </a>
      <Menu
        id="menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="link-list-opt no-bdr text-secondary"
      >
        {options.map((item, index) => {
          return (
            <div key={index}>
              {item.hide === true ? (
                <></>
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href={undefined}
                  tabIndex={index}
                  role="menuitem"
                  className="dropdown-item w-min-140px"
                  onClick={() => item.action()}
                >
                  <Icon name={item.icon} />
                  <span>{item.text}</span>
                </a>
              )}
            </div>
          );
        })}
      </Menu>
    </div>
  );
};
export default MoreOption;

const APIServiceMethod = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
};

const APIServicePath = {
  // COMMON
  categoryList: "common/category-list",

  //PROPERTY
  propertyDetails: "property/detail",
  propertyList: "property/list",

  // PROPERTY CONTACT
  savePropertyContact: "property/save-property-contact",
  removeContact: "property/remove-property-contact",

  // PROPERTY OWNER
  savePropertyOwner: "property/save-property-owner",
  removeOwner: "property/remove-property-owner",

  // PROPERTY Listing-Information
  addListingInformation: "property/save-listing-information",

  // PROPERTY APPLIANCE
  addAppliance: "property/save-property-appliance",
  removeAppliance: "property/remove-property-appliance",

  // PROPERTY MEDIA

  savePropertyMedia: "property/save-property-media",
  removePropertyMedia: "property/remove-property-media",

  // PROPERTY UNIT
  unitAmenities: "unit/amenities-list",
  saveUnit: "unit/save",
  unitList: "unit/list",
  removeUnit: "unit/remove",

  //TENANT
  tenantList: "tenant/list",
  inviteTenant: "tenant/invite",
  tenantDetail: "tenant/detail",
  moderateTenantInvite: "tenant/invitation-moderate",
  pendingInvitation: "tenant/my-pending-invitation",
  processInviteToken: "email-process/action",
  tenantContractComplete: "tenant/rental-contract-complete",
  rentPaymentDetails: "tenant/remain-rent-payment-data",
  payRent: "tenant/pay-rent",
  paymentFee: "payment/fees",

  // Payment API
  // 1. Tenant
  bankClientSecret: "bank/get-token-for-save",
  bankClientSecretForBank: "bank/get-token-for-next-step",
  tenantBank: "bank/list",
  tenantBankRemove: "bank/remove",
  tenantCardRemove: "card/remove-payment-card",
  // tenantPaymentHistory: "tenant/payment-history",
  tenantPaymentChargeList: "tenant/payment-charge-list",
  setupAutoPayment: "tenant/autopay-setting",
  // autoPaymentDetail: "tenant/get-autopay-setting-data",
  cancelAutoPayment: "tenant/cancel-autopay-setting",
  contractDetail: "tenant/get-current-contract-data",

  // 2. PM and Vendor
  bankList: "payout/get-account-list",
  payoutToken: "payout/connect-link",

  payoutBankList: "payout/get-external-account-list", //used for bank dropdown

  // PROPERTY MANAGER
  updatePMProfile: "pm/update-information",

  // PAYMENT CHARGE
  chargeItemHistoryList: "tenant/charge-item-history-list",

  // Unit History List
  unitHistoryList: "tenant/unit-history-list",

  // INVOICE

  addInvoice: "custom-invoice/save",
  cancelInvoice: "custom-invoice/cancel",
  listInvoice: "custom-invoice/list",
  searchInvoice: "custom-invoice/invoice-item-list",

  // Maintenance Request----------------
  maintenanceRequest: "maintenance-request/save",
  maintenanceRequestList: "maintenance-request/list",

  maintenanceRequestDetail: "maintenance-request/detail",

  unitTenantId: "unit/tenant-data-list",
  maintenanceStatusUpdate: "maintenance-request/status-update",
  maintenanceRequestComment: "maintenance-request/comment",
  maintenanceActivityList: "maintenance-request/activity-list",
};

export { APIServiceMethod, APIServicePath };

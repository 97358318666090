import React from "react";
import { Block, Col, PreviewCard, Row } from "../../components/Component";
import Logo from "../../images/logo.svg";
import LogoDark from "../../images/logo-dark.svg";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";

import PlanS1 from "../../images/icons/plan-s1.svg";
import PlanS2 from "../../images/icons/plan-s2.svg";
import PlanS3 from "../../images/icons/plan-s3.svg";

import { UserAccountType } from "../../utils/Utils";
import { useHistory } from "react-router";

export const accountTypeData = [
  {
    id: 1,
    title: "Property manager",
    logo: PlanS1,
    desc: "I manage properties and request quotes from vendors",
    type: UserAccountType.manager,
    pb: false,
  },
  {
    id: 2,
    title: "Service vendor",
    logo: PlanS2,
    desc: "I service properties and provide contractual work",
    type: UserAccountType.vendor,
    pb: true,
  },
  {
    id: 3,
    title: "Resident",
    logo: PlanS3,
    desc: "I need text from your side to place here to show",
    type: UserAccountType.tenant,
    pb: true,
  },
];

const AccountType = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Head title="Sign up" />
      <PageContainer>
        {/* <Block size="lg nk-block-middle"> */}
        <Block className="nk-block-middle nk-auth-body wide-sm">
          <div className="brand-logo pb-1 text-left mx-5">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
            <div className="form-note-s2 text-left pt-1">
              Select the option that best describes your role.
            </div>
          </div>
          <PreviewCard className="card bg-transparent">
            <Row className="g-gs">
              {accountTypeData.map((item) => {
                return (
                  <Col md="4" key={item.id}>
                    <Link
                      to={undefined}
                      onClick={() => {
                        history.push({
                          pathname: `${process.env.PUBLIC_URL}/auth-register`,
                          state: { type: item.type },
                        });
                      }}
                    >
                      <Card
                        className={`card card-bordered text-center h-250px`}
                      >
                        <div className="card-inner">
                          <div className="pricing-media">
                            <img src={item.logo} alt="" />
                          </div>
                          <div className="card-title mx-auto">
                            <h5 className={`title ${item.pb ? "pb-4" : ""}`}>
                              {item.title}
                            </h5>
                            {/* <span className="sub-text">{item.desc}</span> */}
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default AccountType;

import React, { useState, useEffect, useRef } from "react";

import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../components/Component";
import Logo from "../../images/logo.svg";
import LogoDark from "../../images/logo-dark.svg";
import { Row, Spinner, Col, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  AppText,
  COUNTRY_CODE,
  formatPhoneNumber,
  isValidEmail,
  isValidPassword,
  isValidPhone,
  PASSWORD_TOOL_TIP,
} from "../../utils";
import {
  handleScriptLoad,
  loadScript,
} from "../../services/google/autocomplete";
import { signup } from "./AuthData";
import { ConfirmationType } from "./Confirmation";
import { UserAccountType } from "../../utils/Utils";
import { Storage } from "../../services/storage/storage";

const Register = () => {
  const [passState, setPassState] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [errorValue, setErrorValue] = useState(null);
  const [isDataError, setIsDataError] = useState(null);
  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const autoCompleteRef = useRef(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.type === undefined) {
      window.history.pushState(
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
        "auth-login",
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      );
      window.location.reload();
    }

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(onUpdateAddress, autoCompleteRef)
    );

    let emailAction = Storage.getEmailActions();
    if (emailAction && emailAction.email) {
      setFormData((prev) => ({
        ...prev,
        email: emailAction.email,
        isEmailAction: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateAddress = (data) => {
    setSelectedAddress(data);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = () => {
    if (
      formData?.firstname === undefined ||
      formData?.firstname?.length === 0 ||
      formData?.lastname === undefined ||
      formData?.lastname?.length === 0 ||
      formData?.phone === undefined ||
      formData?.phone?.length === 0 ||
      !isValidPhone(formData?.phone) ||
      formData?.email === undefined ||
      formData?.email?.length === 0 ||
      !isValidEmail(formData?.email) ||
      formData?.passcode === undefined ||
      formData?.passcode?.length === 0 ||
      !isValidPassword(formData?.passcode)
    ) {
      setIsDataError(true);
      return;
    }

    if (
      location.state.type !== UserAccountType.tenant &&
      (formData?.company === undefined ||
        formData?.company?.length === 0 ||
        selectedAddress === undefined)
    ) {
      setIsDataError(true);
      return;
    }

    setIsDataError(false);
    setLoading(true);
    var object = formData;
    object["addressData"] = selectedAddress;
    object["type"] = location.state.type;

    signup(object, (_, error) => {
      setLoading(false);
      if (error === null) {
        history.push({
          pathname: `${process.env.PUBLIC_URL}/confirmation`,
          state: {
            type: ConfirmationType.email,
            email: formData.email,
            accountType: location.state.type,
          },
        });
      } else {
        setErrorValue(error.message);
      }
    });
  };
  return (
    <React.Fragment>
      <Head title="Sign up" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-left mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign up</BlockTitle>
                <BlockDes>
                  <p>
                    {location.state?.type === UserAccountType.tenant ? (
                      <>Create new resident account</>
                    ) : (
                      <>Create new {location.state?.type?.toLowerCase()} account</>
                    )}
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorValue && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorValue}{" "}
                </Alert>
              </div>
            )}
            <form
              className="is-alter form-validate"
              onSubmit={handleSubmit(handleFormSubmit)}
              autoComplete="off"
              onKeyDown={(ev) => {
                ev.code === "Enter" && ev.preventDefault();
              }}
            >
              <Row className="g-gs mb-2">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="first-name">
                      First name
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="first-name"
                        name="firstname"
                        maxLength={20}
                        className="form-control-lg form-control"
                        value={formData?.firstname ?? ""}
                        onChange={(ev) => onInputChange(ev)}
                      />
                      {isDataError &&
                        (formData.firstname === undefined ||
                          formData?.firstname?.length === 0) && (
                          <p className="invalid">This field is required</p>
                        )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="last-name">
                      Last name
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="last-name"
                        name="lastname"
                        maxLength={20}
                        className="form-control-lg form-control"
                        value={formData?.lastname ?? ""}
                        onChange={(ev) => onInputChange(ev)}
                      />
                      {isDataError &&
                        (formData.lastname === undefined ||
                          formData?.lastname?.length === 0) && (
                          <p className="invalid">This field is required</p>
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
              {location.state?.type !== UserAccountType.tenant && (
                <>
                  <Row className="g-gs mb-2">
                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="company-name">
                          Company name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            id="company-name"
                            name="company"
                            maxLength={50}
                            className="form-control-lg form-control"
                            value={formData?.company ?? ""}
                            onChange={(ev) => onInputChange(ev)}
                          />

                          {isDataError &&
                            (formData.company === undefined ||
                              formData?.company?.length === 0) && (
                              <p className="invalid">This field is required</p>
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-gs mb-2">
                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="office-address">
                          Office address
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            id="office-address"
                            name="address"
                            placeholder=""
                            className="form-control-lg form-control"
                            onChange={(e) => onUpdateAddress(null)}
                            ref={(event) => {
                              autoCompleteRef.current = event;
                            }}
                          />
                          {isDataError && selectedAddress === null && (
                            <p className="invalid">This field is required</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="g-gs mb-2">
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone">
                      Phone
                    </label>
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {COUNTRY_CODE}
                          </span>
                        </div>
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          maxLength={14}
                          minLength={14}
                          className="form-control-lg form-control"
                          value={formData?.phone ?? ""}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [e.target.name]: formatPhoneNumber(
                                "",
                                e.target.value
                              ),
                            });
                          }}
                        />
                      </div>
                      {isDataError &&
                      (formData.phone === undefined ||
                        formData.phone?.length === 0) ? (
                        <span className="invalid">This field is required</span>
                      ) : (
                        isDataError &&
                        isValidPhone(formData.phone) === false && (
                          <span className="invalid">Invalid phone number</span>
                        )
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="g-gs mb-2">
                <Col md="12">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="fv-email">
                        {AppText.email}
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        bssize="lg"
                        id="fv-email"
                        name="email"
                        className="form-control-lg form-control"
                        value={formData?.email ?? ""}
                        onChange={(ev) => onInputChange(ev)}
                        disabled={formData?.isEmailAction ?? false}
                      />
                      {isDataError &&
                      (formData.email === undefined ||
                        formData.email?.length === 0) ? (
                        <span className="invalid">This field is required</span>
                      ) : (
                        isDataError &&
                        isValidEmail(formData.email) === false && (
                          <span className="invalid">
                            {AppText.invalidEmail}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="g-gs mb-4">
                <Col md="12">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="password">
                        {AppText.password}
                      </label>
                      <TooltipComponent
                        icon="help-fill"
                        iconClass="card-hint"
                        direction="right"
                        id="password"
                        text={PASSWORD_TOOL_TIP}
                      />
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon
                          name="eye"
                          className="passcode-icon icon-hide"
                        ></Icon>
                        <Icon
                          name="eye-off"
                          className="passcode-icon icon-show"
                        ></Icon>
                      </a>
                      <input
                        type={passState ? "text" : "password"}
                        id="password"
                        name="passcode"
                        className={`form-control-lg form-control ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                        value={formData?.passcode ?? ""}
                        onChange={(ev) => onInputChange(ev)}
                      />
                      {isDataError &&
                      (formData.passcode === undefined ||
                        formData.passcode?.length === 0) ? (
                        <span className="invalid">This field is required</span>
                      ) : (
                        isDataError &&
                        isValidPassword(formData.passcode) === false && (
                          <span className="invalid">Invalid password</span>
                        )
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="12">
                  <div className="form-group">
                    <Button
                      type="submit"
                      color="primary"
                      size="lg"
                      className="btn-block"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Create account"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Have an account?{" "}
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Sign in</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
